.about
  &__column
    display: inline-block
    vertical-align: middle
    width: 49%
  &__banner
    text-align: right
  &__play-block
    margin-top: 15px
    text-align: center
  &__play-item
    display: inline-block
    margin: 0 15px