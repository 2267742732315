@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700')
@import url('https://fonts.googleapis.com/css?family=Lobster')

$nav-color: #513f89
$nav-footer-color: #37385b
$nav-copy-color: #2c2d4b
$section-bgcolor: #04beac
$btn-get-start-color: #31bce3
$btn-get-bonus-color: #03a663
$section-title-color: #eb0a4f
$section-text-color: #5e6d8a

*
  font-family: 'Noto Sans', sans-serif
  font-size: 14px

a
  color: white

.inner
  position: relative
  margin: 0 auto
  width: 85%
  max-width: 1500px

.section
  padding: 40px 0
  &__title
    margin-bottom: 15px
    color: #eb0a4f
    font-size: 30px
    font-weight: bold
  h2
    color: #eb0a4f
    font-size: 25px
    font-weight: bold
  p
    margin-bottom: 15px
    color: #5e6d8a
    font-size: 15px
  ul
    margin-left: 25px
    list-style: circle
    color: #5e6d8a
    font-size: 15px