.btn
  text-transform: uppercase
  font-weight: bold
  &__nav
    padding: 10px 28px
    text-decoration: none
    border-radius: 25px
    background: #31bce3
  &__nav:hover
    background: #5c7acb
  &__get-bonus,
  &__join-now
    padding: 15px 45px
    font-size: 24px
    text-transform: uppercase
    text-decoration: none
    font-weight: bold
    background-color: #03a663
    border-radius: 30px
    box-shadow: 0 3px #02da81
  &__get-bonus:hover,
  &__join-now:hover
    background-color: #0a9bb2
  &__play-now
    color: #03a663
    font-weight: bold
    text-transform: uppercase
  &__play-now:hover
    color: #006da6
  &__join-now
    display: inline-block
    padding: 15px 55px
    margin-top: 10px