.nav
  padding: 15px 0
  &__row
    display: inline-block
    vertical-align: middle
  &__logo
    width: 20%
  &__logo-link
    color: white
    text-transform: uppercase
    text-decoration: none
    font-size: 24px
    font-weight: bold
  &__menu,
  &__text
    width: 59%
    text-align: center
  &__menu-item
    margin: 0 10px
    font-weight: bold
    text-decoration: none
  &__btn-block
    width: 20%
    text-align: right
  &__text
    color: white
    font-size: 18px
    font-weight: bold

.pink
    color: #fe4277
    text-transform: uppercase
    font-size: 24px
    font-weight: bold

@import button