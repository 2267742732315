.why
  text-align: center
  background-color: $section-bgcolor
  &__title
    margin-bottom: 65px
    color: white
    font-size: 36px
    font-weight: bold
    text-transform: uppercase
  &__item
    position: relative
    display: inline-block
    margin: 0 10px
    padding: 25px
    vertical-align: bottom
    width: 295px
    height: 145px
    line-height: 145px
    background-color: white
    border-radius: 10px
    box-sizing: border-box
  &__item:before
    display: block
    position: absolute
    left: 50%
    top: -50px
    transform: translateX(-50%)
  &__safe:before
    content: url("../img/why-1.png")
  &__secure:before
    content: url("../img/why-2.png")
  &__play:before
    content: url("../img/why-3.png")
  &__text
    text-transform: uppercase
    color: $section-text-color
    font-size: 18px
    font-weight: bold