.winners
  text-align: center
  background-color: $section-bgcolor
  &__item
    display: inline-block
    padding: 25px 15px
    margin: 0 15px 15px
    width: 300px
    height: 415px
    background-color: white
    border-radius: 10px
    box-sizing: border-box
  &__tape-late-win
    +tape(250px, 38px, 12px, #ffc000)
  &__tape-top-win
    +tape(250px, 38px, 12px, #eb0a4f)
  &__tape-jackpot
    +tape(250px, 38px, 12px, #03a663)
  &__game-block
    padding: 15px 0
    text-align: initial
    border-bottom: 1px solid #bdcae3
  &__game-block:last-child
    border-bottom: none
  &__game-item
    display: inline-block
    vertical-align: middle
  &__img-block
    margin-right: 10px
  &__game-title
    color: #5e6d8a
  &__game-num
    color: #de2454
    font-size: 25px
    font-weight: bold