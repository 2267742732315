.footer
  &__nav
    background-color: $nav-color
  &__block-link
    padding: 25px 0
    background-color: $nav-footer-color
  &__column
    display: inline-block
    vertical-align: middle
  &__column-link
    width: 88%
  &__column-img
    text-align: right
    width: 10%
  &__menu-block
    column-count: 3
  &__menu-item
    margin: 7px 0
  &__menu-item:first-child
    margin-top: 0
  &__menu-link
    color: #a7b2e6
  &__copy
    padding: 20px 0
    background-color: $nav-copy-color
  &__copy-item
    display: inline-block
    vertical-align: middle
  &__copy-description
    margin-right: 10px
    width: 29%
    color: #a7b2e6
  &__copy-description:before
    content: url("../img/footer/stop.png")
    display: inline-block
    margin-right: 10px
    vertical-align: middle
  &__copy-social
    width: 40%
  &__copy-img
    display: inline-block
    margin: 0 12px
    vertical-align: middle
  &__copy-text
    width: 28%
    text-align: right
    color: #51527c