@media (min-width: 320px) and (max-width: 1137px)
  .why__item
    margin-bottom: 60px
  .why__item:last-child
    margin-bottom: 0

.inner
  @include mobile
    width: 95%
  @include laptope
    width: 95%

.header
  @include desktope
    &__bonus-item:last-child
      margin-top: 125px
  @include laptope
    &__block
      padding: 0
    &__bonus-item:last-child
      margin-top: 100px
    &__bonus-item:nth-child(2):before
      right: -30px
  @include mobile
    &__block
      padding: 0
    &__title
      font-size: 30px
    &__bonus-item
      margin: 0 0 15px 0
      padding: 40px 0 30px 0
      width: 280px
      height: 291px
      background-size: contain
    &__bonus-num
      font-size: 50px
    &__bonus-tape_first:before
      top: -35px
    &__bonus-item:first-child:before
      display: none
    &__bonus-item:nth-child(2):before
      right: -5px
      top: 83px
    &__bonus-item:last-child
      margin-top: 100px
    &__bonus-item:nth-child(3):before
      right: 10px
    &__bonus-item:nth-child(3):after
      display: none
    &__bonus-btn_third:after
      right: 10px
      bottom: -55px

.nav
  @include laptope
    &__row
      display: block
      text-align: center
      width: 100%
    &__btn-block
      margin: 15px 0
  @include mobile
    &__row
      display: block
      text-align: center
      width: 100%
    &__btn-block
      margin: 15px 0
    &__menu-item
      display: inline-block

.about
  @include desktope
    &__banner-img
      width: 100%
  @include laptope
    &__column
      display: block
      width: 100%
    &__banner
      text-align: center
  @include mobile
    &__column
      display: block
      width: 100%
    &__banner
      text-align: center
    &__banner-img
      width: 100%

.jackpot
  @include laptope
    &__item
      display: block
      width: 100%
    &__img-block
      margin-top: 20px
      text-align: center
  @include mobile
    &__item
      display: block
      width: 100%
    &__img-block
      margin-top: 20px
      text-align: center
    &__img
      width: 100%

.footer
  @include laptope
    &__copy-item
      display: block
      margin: 5px 0
      width: 100%
      text-align: center
  @include mobile
    &__column
      display: block
      width: 100%
    &__menu-block
      column-count: 1
      text-align: center
    &__menu-item
      display: inline-block
      margin: 5px 10px
    &__column-img
      text-align: center
    &__copy
      text-align: center
    &__copy-item
      display: block
      width: 100%
    &__copy-text
      text-align: center


.btn
  @include mobile
    &__get-bonus
      padding: 7px 30px
      font-size: 20px

.winners
  @include mobile
    &__item
      margin: 0 0 15px 0
      width: 280px
    &__game-title
      font-size: 12px

@include mobile
  .section__title
    font-size: 24px