$units: 1px !default;

@mixin screen($res-min, $res-max)
{
  @media screen and ( min-width: if(unitless($res-min), $res-min*$units, $res-min) ) and ( max-width: if(unitless($res-max), $res-max*$units, $res-max) )
  {
    @content;
  }
}

@mixin mobile {
  @media (min-width: 320px) and (max-width: 600px) {
    @content
  }
}

@mixin laptope {
  @media (min-width: 601px) and (max-width: 1029px) {
    @content
  }
}

@mixin desktope {
  @media (min-width: 1030px) and (max-width: 1218px) {
    @content
  }
}

@mixin max-screen($res)
{
  @media screen and ( max-width: if(unitless($res), $res*$units, $res) )
  {
    @content;
  }
}

@mixin min-screen($res)
{
  @media screen and ( min-width: if(unitless($res), $res*$units, $res) )
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- hdpi ------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

// Based on bourbon hidpi-media-queries file (https://github.com/thoughtbot/bourbon/blob/master/app/assets/stylesheets/css3/_hidpi-media-query.scss)
// HiDPI mixin. Default value set to 1.3 to target Google Nexus 7 (http://bjango.com/articles/min-device-pixel-ratio/)

@mixin hdpi($ratio: 1.3)
{
  @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min--moz-device-pixel-ratio: $ratio),
  only screen and (-o-min-device-pixel-ratio: #{$ratio}/1),
  only screen and (min-resolution: #{round($ratio*96)}dpi),
  only screen and (min-resolution: #{$ratio}dppx)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- iphone ----------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------


@mixin iphone
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  {
    @content;
  }
}

@mixin iphone3
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 1)
  {
    @content;
  }
}

@mixin iphone3-portrait
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 1) and (orientation:portrait)
  {
    @content;
  }
}

@mixin iphone3-landscape
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 1) and (orientation:landscape)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- iphone-retina ---------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin iphone4
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3)
  {
    @content;
  }
}

@mixin iphone4-portrait
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) and (orientation:portrait)
  {
    @content;
  }
}

@mixin iphone4-landscape
{
  @media only screen and (min-device-width: 320px)  and (max-device-width: 480px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) and (orientation:landscape)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- iphone-5 --------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin iphone5
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71)
  {
    @content;
  }
}

@mixin iphone5-portrait
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:portrait)

  {
    @content;
  }
}

@mixin iphone5-landscape
{
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px)
  and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:landscape)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- ipad + ipad mini ------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin ipad
{
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 1)
  {
    @content;
  }
}

@mixin ipad-portrait
{
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 1) and (orientation:portrait)
  {
    @content;
  }
}

@mixin ipad-landscape
{
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 1) and (orientation:landscape)
  {
    @content;
  }
}

// ---------------------------------------------------------------------------------------------------------------------
// --- ipad-retina -----------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------

@mixin ipad-retina
{
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 2)
  {
    @content;
  }
}

@mixin ipad-retina-portrait
{
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 2) and (orientation:portrait)
  {
    @content;
  }
}

@mixin ipad-retina-landscape
{
  @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
  and (-webkit-device-pixel-ratio: 2) and (orientation:landscape)
  {
    @content;
  }
}