.header
  background-image: -ms-radial-gradient(center, ellipse farthest-corner, #3E6A9C 0%, #513F89 80%)
  background-image: -moz-radial-gradient(center, ellipse farthest-corner, #3E6A9C 0%, #513F89 80%)
  background-image: -o-radial-gradient(center, ellipse farthest-corner, #3E6A9C 0%, #513F89 80%)
  background-image: -webkit-gradient(radial, center center, 0, center center, 286, color-stop(0, #3E6A9C), color-stop(80, #513F89))
  background-image: -webkit-radial-gradient(center, ellipse farthest-corner, #3E6A9C 0%, #513F89 80%)
  background-image: radial-gradient(ellipse farthest-corner at center, #3E6A9C 0%, #513F89 80%)
  &__block
    padding: 25px 0
  &__title
    text-align: center
    color: white
    font-size: 44px
    font-weight: bold
    text-transform: uppercase
  &__bonus-block
    position: relative
    margin-top: 30px
    text-align: center
  &__bonus-item
    position: relative
    display: inline-block
    margin: 0 12px 15px 12px
    padding: 45px 0 30px 0
    text-align: center
    width: 314px
    height: 326px
    background: url("../img/banner-slot.png")
  &__bonus-item:first-child:before
    content: url("../img/horse.png")
    position: absolute
    left: -115px
  &__bonus-item:first-child:after
    content: url("../img/money/money-1-2.png")
    position: absolute
    left: -50px
    bottom: -95px
    z-index: 10
  &__bonus-item:nth-child(2):before
    content: url("../img/money/money-2-1.png")
    position: absolute
    top: 50px
    right: -40px
    z-index: 10
  &__bonus-item:nth-child(2):after
    content: url("../img/money/money-2-2.png")
    position: absolute
    left: 25px
    bottom: -58px
    z-index: 10
  &__bonus-item:nth-child(3):before
    content: url("../img/sheep.png")
    position: absolute
    top: -110px
    right: -30px
    z-index: 10
  &__bonus-item:nth-child(3):after
    content: url("../img/banner-lever.png")
    position: absolute
    top: 50px
    right: -57px
    z-index: 10
  &__bonus-tape
    margin: 0 auto
    width: 214px
    height: 38px
    line-height: 38px
    color: white
    text-transform: uppercase
    font-size: 24px
    font-weight: bold
    background: url("../img/banner-tip.png") no-repeat
    &_first:before
      content: url("../img/money/money-1-1.png")
      position: absolute
      top: -40px
      z-index: 10
  &__bonus-num
    color: #eb0a4f
    font-size: 70px
    font-weight: bold
  &__bonus-text
    font-size: 26px
    font-family: 'Lobster', cursive
    color: #4f6b9f
  &__bonus-btn
    margin-top: 30px
    &_third:after
      content: url("../img/money/money-3.png")
      position: absolute
      bottom: -35px
      z-index: 10

.header-page
  background: #513f89

@import nav